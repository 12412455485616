




import { Component, Vue } from "vue-property-decorator";
import { Article } from "@/types/shop/article";
import AlertModule, { AlertType } from "@/store/modules/alert";
import ConfiguratorModule, {
    ConfiguratorConfiguredConfiguration,
} from "@/store/modules/configurator";
import axios, { APIResponse } from "@/plugins/axios";

@Component
export default class ConfiguratorConfiguredConfigurationDetails extends Vue {
    private async created() {
        if (!("id" in this.$route.params)) {
            this.$router.push({ name: "configurator" });
        }

        try {
            const configuredConfiguration =
                await this.fetchConfiguredConfigurationById(
                    parseInt(this.$route.params.id)
                );
            await ConfiguratorModule.loadConfiguredConfiguration(
                configuredConfiguration
            );

            this.$router.push({ name: "configuratorOverview" });
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });

            this.$router.push({ name: "configurator" });
        }
    }

    /**
     * Fetches configuredConfiguration by id
     *
     * @param id configuredConfiguration id
     *
     * @returns Promise<Article>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchConfiguredConfigurationById(
        id: number
    ): Promise<ConfiguratorConfiguredConfiguration> {
        const response = (
            await axios.get<APIResponse<ConfiguratorConfiguredConfiguration>>(
                "/admin/configurator/configuration/configured/" + id
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
